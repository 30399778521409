import classNames from "classnames";
import React from "react";


const FindPayer = (props) => {

    let {
        submitBtnText,
        onChangeHandler,
        handleClick,
        handleKeyPress,
        submitFunction,
        errorMsgEmail,
        isParentFullContainer,
    } = props;


    return (
        <div>
            <div className="header-sub mt-4 mb-2 row">
                {/* <div className="col-md-5 col-sm-12 col-xs-12"> */}
                {/* <input
                    type="text"
                    className="header-label custom-input-border-css"
                    id="exampleInputName1"
                    data-cy="payerPatientId"
                    aria-describedby="nameHelp"
                    placeholder="*Enter Patient ID"
                    onBlur={(e) => patientPayerHandler(e)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    onChange={(e) => patientChangeHandler(e)}
                    onClick={(e) => handleClick(e)}
                  /> */}
                {/* <span className="color-text">{errorMsgPatient}</span> */}
                {/* </div> */}
                <div
                    className={`${isParentFullContainer ? "col-md-5 " : "col-md-8"} col-sm-12 col-xs-12 admin-portal-input`}>
                    <input
                        type="text"
                        className="header-label custom-input-border-css ml-4"
                        id="exampleInputName2"
                        data-cy="payerEmailPhone"
                        aria-describedby="nameHelp"
                        placeholder="Payer: Last, Email, Phone; Patient: Last, ID#"
                        // onBlur={(e) => payerEmailhandler(e)}
                        onBlur={(e) => onChangeHandler(e)}
                        onChange={(e) => onChangeHandler(e)}
                        onClick={(e) => handleClick(e)}
                        onKeyPress={(e) => handleKeyPress(e, submitFunction)}
                        // autoComplete="none"
                    />

                    <span className="color-text">{errorMsgEmail}</span>
                </div>
                <div
                    className={`${isParentFullContainer ? "col-md-2 " : "col-md-4"} col-sm-12 col-xs-12 admin-portal-input`}>
                    <button
                        type="submit"
                        data-cy="payerPatientBtn"
                        // className={classNames("sos-custom-btn ml-4 sos-custom-btn-css")}
                        style={{height: "100%"}}
                        className={
                            isParentFullContainer ?
                                classNames("sos-custom-btn ml-4 sos-custom-btn-css sos-secondary-btn-padding")
                                : classNames("sos-secondary-btn ml-4 sos-secondary-btn-css sos-secondary-btn-padding")
                        }
                        onClick={(e) => submitFunction(e)}
                    >
                        {submitBtnText}
                    </button>
                </div>

            </div>
        </div>)
}

export default FindPayer;
