import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {Button, ButtonGroup} from 'react-bootstrap';
import NameCase from "namecase";

import {
    DollarSignIcon, NewMessageIcon, TrashCanIcon, CheckBoxIcon, SendIcon,
} from "../../Components/Icons";
import {
    deleteUserData, markNotADuplicate, getAllUsers, redirectPayUserForAdminLookup, getPayerUserById, getAdminUserData
} from "../../Actions/authActions";
import Table from "./../AdminDashboard/ReactTable/ReactTableMain";
import {subDomain, amountFormatterWithZero} from "../../utils";
import sumBy from "lodash/sumBy";
import map from "lodash/map";
import CustomPopoverDelete from "../../Components/CustomPopoverDelete";
import CustomePopUp from "../../Components/customePopUp";
import {DELETE_USERDATA_INITIAL, FETCH_GETALL_DATA_SUCCESS} from "../../constants";
import "./payersTable.css";
import SendIntakeFormModal from "../../Components/SendIntakeFormModal";
import ReactTableServerSide from "./ReactTable/ReactTableServerSide";
import axios from "axios";
import {executeGet} from "../../Api";

const PayersTable = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const userAdmin = props.userAdmin;
    const {themeColor1} = props || "";
    const {match} = props || {};
    const params = useParams();
    const subdomain = params.subdomain || subDomain();

    const user = useSelector((state) => state.auth.alluser);
    const [userData, setUserData] = useState(user);
    const alluserTotalPages = useSelector((state) => state.auth.alluserTotalPages);
    const alluserTotalCount = useSelector((state) => state.auth.alluserTotalCount);
    const alluserSearchResultCount = useSelector((state) => state.auth.alluserSearchResultCount);
    const alluserSearchResultPages = useSelector((state) => state.auth.alluserSearchResultPages);


    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [showErrorPopUp, setShowErrorPopUp] = useState(false);
    const [payerForDeletion, setPayerForDeletion] = useState({});
    const [payerForDeletionTotal, setPayerForDeletionTotal] = useState("0.00");
    const [tablePropsForDeletion, setTablePropsForDeletion] = useState(null);
    const [showPayersWithDups, setShowPayersWithDups] = useState(false);
    const [someHasDuplicateErr, setSomeHasDuplicateErr] = useState(false);

    const [isSendOpen, setIsSendOpen] = useState(false);
    const [intakeFormToSend, setIntakeFormToSend] = useState({});
    const [selectedPayer, setSelectedPayer] = useState(null);

    const deleteUserDataLoading = useSelector((state) => state.auth.deleteUserDataLoading);
    const deleteUserDataSuccess = useSelector((state) => state.auth.deleteUserDataSuccess)
    const deleteUserDataError = useSelector((state) => state.auth.deleteUserDataError)


    // const [showOptionalColumns, setShowOptionalColumns] = useState(localStorage.getItem("showOptionalColumns") === "true");


    const adminUser = useSelector(state => state.auth.adminUser);
    const intakeforms = useSelector((state) => state.auth.adminUser.intakeforms);

    useEffect(() => {
        if (adminUser && !Object.keys(adminUser).length) {
            dispatch(getAdminUserData(subdomain));
            // dispatch(getMessage(subdomain));
            // dispatch(getUserforms(subdomain));
        }
    }, [!adminUser]);


    useEffect(() => {
        if (deleteUserDataSuccess) {
            setShowPopUpDelete(false);
            setShowSuccessPopUp(true);
        }
        if (deleteUserDataError) {
            setShowPopUpDelete(false);
            setShowErrorPopUp(true);
        }
    }, [deleteUserDataSuccess, deleteUserDataError]);

    const fetchUsers = async ({currentPage, limit, sortBy, sortDirection, searchTerm}) => {
        try {
            const buildQuery = {
                subdomain: subdomain,
                page: currentPage,
                limit: limit,
                searchTerm: searchTerm,
                sortBy: sortBy,
                sortDirection: sortDirection,
            };
            const url = `users/allUsers?${new URLSearchParams(buildQuery).toString()}`;
            const result = await executeGet(url, {})
                .then((res) => {
                    const {data} = res || {};
                    return data;
                });
            return {
                data: result.users,
                total: result.totalCount,
                totalPages: result.totalPages,
                currentPage: result.page,
                pageSize: result.pageSize,
            };
        } catch (error) {
            console.error("Error fetching data:", error);
            return {data: [], total: 0};
        }
    };


    useEffect(() => {
        setUserData(user);
    }, [user]);

    useEffect(() => {
        const isDuplicateErrors = userData && userData.length > 0 && userData.some(user => user.hasDuplicateErr === true);
        setSomeHasDuplicateErr(isDuplicateErrors || false);
    }, [userData]);

    const handleDelete = (row, tableProps) => {
        setTablePropsForDeletion(tableProps);
        setShowPopUpDelete(true);
        setPayerForDeletion(row);
        setPayerForDeletionTotal(amountFormatterWithZero(totalAmounts(row)));
    }

    const handleMarkNotADuplicate = (row, tableProps) => {
        dispatch(markNotADuplicate(row.id || row._id, subdomain));
    }

    const handlePay = (row) => {
        dispatch(getPayerUserById({subdomain: subdomain, id: row.id}));
        dispatch(redirectPayUserForAdminLookup(row, subdomain, navigate));
    }

    const deletePayer = (payerForDeletion) => {
        dispatch(deleteUserData(payerForDeletion._id, subdomain));
    };


    const handleClosePopUp = () => {
        setShowPopUpDelete(false);
        setPayerForDeletion({});
        setPayerForDeletionTotal("0.00");
        setTablePropsForDeletion(null);
    }

    const handleSuccessClosePopUp = () => {
        setShowSuccessPopUp(false);

        const dataCopy = [...userData];
        dataCopy.splice(tablePropsForDeletion, 1);
        setUserData(dataCopy);
        setPayerForDeletion({});
        setPayerForDeletionTotal("0.00");
        setTablePropsForDeletion(null);
        dispatch({type: DELETE_USERDATA_INITIAL});
    }

    const handleErrorClosePopUp = () => {
        setShowErrorPopUp(false);
        dispatch({type: DELETE_USERDATA_INITIAL});
    }

    const toggleShowPayersWithDuplicateErrors = () => {
        setShowPayersWithDups(!showPayersWithDups);
    }

    const toggleShowAllColumns = () => {
        setShowOptionalColumns(!showOptionalColumns);
        localStorage.setItem("showOptionalColumns", (!showOptionalColumns).toString());
    }

    const totalAmounts = (row) => {
        return sumBy(map(row.patientPayer, (i) => ({
            ...i, accountBalance: parseFloat(i.accountBalance) || 0,
        })), "accountBalance");
    };

    const displayPatientPayers = (row, property) => {

        return (row.patientPayer.map((v, i) => {
            let displayText = "";
            if (property === "patientName") {
                displayText = NameCase(`${v.patientFirst} ${v.patientMiddle} ${v.patientLast}`);
            } else if (property === "accountBalance") {
                displayText = `$${amountFormatterWithZero(v[property])}`;
            } else if (property === "patientEmail") {
                displayText = (v[property] || '').toLowerCase();
            } else {
                displayText = v[property];
            }
            return (<table
                style={{
                    tableLayout: "fixed", width: "100%",
                }}
                key={i}
            >
                <tbody>

                <tr style={{width: "100%"}}>
                    <td style={{width: "100%"}}>{displayText}</td>
                </tr>
                </tbody>
            </table>)
        }))
    };

    const actionsTable = (props, tableProps, showPayersWithDups) => {

        return (<>
            {/*<table>*/}
            {/*<tbody>*/}
            {/*<tr  style={{width: "6vw"}}>*/}
            <div style={{display: "flex", justifyContent: "space-around"}} key={props.row.id}>
                {!showPayersWithDups && <>
                    <div
                        className="text-center"
                        style={{
                            cursor: "pointer", backgroundColor: "inherit",
                        }}
                        onClick={() => {
                            setSelectedPayer(props.row.original);
                            setIsSendOpen(true);
                        }}
                    >
                        <SendIcon color={themeColor1} height="1.4vw" width="1.4vw"/>
                        <div>Send Form</div>
                    </div>
                    <div
                        className='text-center'
                        style={{
                            cursor: "pointer", backgroundColor: "inherit", paddingRight: "0.2vw"
                        }}
                        onClick={() => handlePay(props.row.original)}
                    >
                        <DollarSignIcon color={themeColor1} height={"1.4vw"}/>
                        <div>Payment</div>
                    </div>
                    <div
                        className="text-center"
                        style={{
                            cursor: "pointer", backgroundColor: "inherit",
                        }}
                        onClick={() => handleDelete(props.row.original, tableProps)}
                    >
                        <TrashCanIcon color={themeColor1} height={"1.4vw"}/>
                        <div>Delete</div>
                    </div>
                </>}
                {showPayersWithDups && <div
                    className="text-center"
                    style={{
                        cursor: "pointer", backgroundColor: "inherit",
                    }}
                    onClick={() => handleMarkNotADuplicate(props.row.original, tableProps)}
                >
                    {/*<TrashCanIcon color={themeColor1} height={"1.4vw"}/>*/}
                    <CheckBoxIcon color={themeColor1} height={"1.4vw"} width={"1.4vw"}/>
                    <div>Remove from duplicates list</div>

                </div>}
            </div>
            {/*</tbody>*/}
            {/*</table>*/}
        </>)
    }

    // const columns = [{accessorKey: "id", header: "ID"}, {accessorKey: "Payer Name", header: "Payer Name"}, {
    //     accessorKey: "email",
    //     header: "Email"
    // }, {accessorKey: "phone", header: "Phone"},];

    // const colWidths = [];

    function convertToTitleCase(str) {
        if (!str) {
            return "";
        }
        return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
    }

    const columns = React.useMemo(() => [
        {
            id: "payerLast",
            header: "Payer Name",
            colWidthWeight: 7,
            enableSorting: true,
            accessorFn: (row) => `${row.payerLast}, ${row.payerFirst} ${row.payerMiddle}`,
            cell: (props) => NameCase(`${props.row.original.payerLast}, ${props.row.original.payerFirst} ${props.row.original.payerMiddle}`),
        },
        {
            id: "payerId",
            header: "Payer ID",
            colWidthWeight: 5,
            enableSorting: true,
            accessorKey: "payerId",
        },
        {
            id: "payerEmail",
            header: "Payer Email",
            colWidthWeight: 15,
            enableSorting: true,
            accessorKey: "payerEmail",
            cell: (props) => props.row.original.payerEmail && props.row.original.payerEmail.startsWith("*fake*") ? "" : (props.row.original.payerEmail || ""),
        },
        {
            id: "payerCell",
            header: "Payer Cell",
            colWidthWeight: 8,
            enableSorting: true,
            accessorKey: "payerCell",
            cell: (props) => props.row.original.payerCell && props.row.original.payerCell.startsWith("*fake*") ? "" : (props.row.original.payerCell || ""),
        },
        {
            id: "totalAmounts",
            header: "Total Due",
            colWidthWeight: 7,
            enableSorting: false,
            accessorKey: "totalAmounts",
            cell: (props) => `$${amountFormatterWithZero(props.row.original.totalAmounts)}`,
        },
        {
            header: "Patient Number",
            colWidthWeight: 8,
            enableSorting: false,
            accessorFn: (row) => row.patientPayer.map((ptPayer) => ptPayer.patientNumber).join("_"),
            cell: (props) => displayPatientPayers(props.row.original, "patientNumber"),
        },
        {
            header: "Patient Name",
            colWidthWeight: 9,
            enableSorting: false, // accessorFn: (row) => displayPatientPayers(row, "patientName"),
            accessorFn: (row) => row.patientPayer.map((ptPayer) => `${ptPayer.patientFirst} ${ptPayer.patientMiddle} ${ptPayer.patientLast}`).join("_"),
            cell: (props) => displayPatientPayers(props.row.original, "patientName"),
        },
        {
            header: "Patient Email",
            colWidthWeight: 15,
            enableSorting: false,
            accessorFn: (row) => row.patientPayer.map((ptPayer) => ptPayer.patientEmail).join("_"),
            cell: (props) => displayPatientPayers(props.row.original, "patientEmail"),
        },
        {
            header: "Patient cell",
            colWidthWeight: 8,
            enableSorting: false,
            accessorFn: (row) => row.patientPayer.map((ptPayer) => ptPayer.patientcell).join("_"),
            cell: (props) => displayPatientPayers(props.row.original, "patientcell"),
        },
        {
            id: 'accountBalance',
            header: "Patient Amount",
            colWidthWeight: 7,
            enableSorting: true,
            accessorFn: (row) => row.patientPayer.map((ptPayer) => ptPayer.accountBalance).join("_"),
            cell: (props) => displayPatientPayers(props.row.original, "accountBalance"),
        },
        {
            header: "Actions",
            colStyle: {"textAlign": "center"},
            colWidthWeight: 15,
            disableFilters: true,
            enableSorting: false,
            accessorFn: row => row.id,
            cell: (props, tableProps) => (actionsTable(props, tableProps, showPayersWithDups)),
        }
    ], [userData, showPayersWithDups]);

    const colWidths = columns.map(o => o.colWidthWeight).map(x => x.toString() + "%");


    return (<>
        <div className=" container-fluid">
            {<div style={{display: "flex"}}>
                {/*<div style={{display:"inline-block", fontSize:"24px", marginRight:"1em"}}>View:</div>*/}
                {/*onClick={() => setRSelected(1)}*/}
                {/*<div*/}
                {/*    style={{display: "inline-block"}}*/}
                {/*    className={`sos-custom-btn-new-message ${showOptionalColumns ? "sos-custom-btn-css" : "sos-secondary-btn-css"} mb-1 ps-3 pe-3`}*/}
                {/*    onClick={() => toggleShowAllColumns()}*/}
                {/*>*/}
                {/*    {`${showOptionalColumns ? "Hide" : "Show"} Optional Columns`}*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    style={{display: "inline-block"}}*/}
                {/*    className={`sos-custom-btn-new-message ${showOptionalColumns ? "sos-custom-btn-css" : "sos-secondary-btn-css"} mb-1 ms-3 ps-3 pe-3`}*/}
                {/*    onClick={() => toggleShowAllColumns()}*/}
                {/*>*/}
                {/*    Table Settings*/}
                {/*</div>*/}
                {someHasDuplicateErr && <div
                    style={{display: "inline-block", marginLeft: "auto"}}
                    className={`sos-custom-btn-new-message ${showPayersWithDups ? "sos-custom-btn-css" : "sos-secondary-btn-css"} mb-1 w-25 float-right`}
                    onClick={() => toggleShowPayersWithDuplicateErrors()}
                >
                    {showPayersWithDups ? "Show All Payers" : "Show Payers with Duplicate Errors"}
                </div>}
            </div>}
            {showPopUpDelete && <CustomPopoverDelete
                title={"Delete Payer and Patients?"}
                subtext={"The following payer record with its associated patient records will be removed from the portal. " + "If these patients are associated with other payers, those records would remain associated with those payers."}
                show={showPopUpDelete}
                isLoading={deleteUserDataLoading}
                deletePayer={deletePayer}
                close={handleClosePopUp}
                displayPatientPayers={displayPatientPayers}
                payerForDeletion={payerForDeletion}
                payerForDeletionTotal={payerForDeletionTotal}
                themeColor1={themeColor1}
            />}
            {showSuccessPopUp ? (<CustomePopUp
                message={"Deletion successful"}
                openModal={showSuccessPopUp}
                onCloseModal={handleSuccessClosePopUp}
            />) : ""}

            {showErrorPopUp ? (<CustomePopUp
                message={"Error when deleting the payer"}
                openModal={showErrorPopUp}
                onCloseModal={handleErrorClosePopUp}
            />) : ""}


            {<ReactTableServerSide
                fetchData={fetchUsers}
                columns={columns}
                colWidths={colWidths}
                searchPlaceholder='Search by Payer or Patient name, email, or cell'
                initialSortState={{sortBy: "payerLast", desc: false}}
                // currentPage={currentPage}
                // setCurrentPage={setCurrentPage}
                // limit={limit}
                // setLimit={setLimit}
                // searchTerm={searchTerm}
                // setSearchTerm={setSearchTerm}
                // // getSearchData={getSearchData}
                // data={showPayersWithDups ? userData.filter(user => user.hasDuplicateErr) : userData}
                // colWidths={!showPayersWithDups
                //     ? ["7%", "5%", "18%", "8%", "7%", "8%", "9%", "15%", "8%", "7%", "14%"]
                //     : ["7%", "5%", "12%", "8%", "7%", "8%", "9%", "15%", "8%", "7%", "20%"]}
                // noDataMessage={"No data"}
            />}


            {/*{userData && <Table*/}
            {/*    currentPage={currentPage}*/}
            {/*    setCurrentPage={setCurrentPage}*/}
            {/*    limit={limit}*/}
            {/*    setLimit={setLimit}*/}
            {/*    searchTerm={searchTerm}*/}
            {/*    setSearchTerm={setSearchTerm}*/}
            {/*    // getSearchData={getSearchData}*/}
            {/*    columns={columns}*/}
            {/*    data={showPayersWithDups ? userData.filter(user => user.hasDuplicateErr) : userData}*/}
            {/*    // colWidths={!showPayersWithDups*/}
            {/*    //     ? ["7%", "5%", "18%", "8%", "7%", "8%", "9%", "15%", "8%", "7%", "14%"]*/}
            {/*    //     : ["7%", "5%", "12%", "8%", "7%", "8%", "9%", "15%", "8%", "7%", "20%"]}*/}
            {/*    colWidths={colWidths}*/}
            {/*    noDataMessage={"No data"}*/}
            {/*/>}*/}
            {selectedPayer && <SendIntakeFormModal
                selectedPayer={selectedPayer}
                adminUser={adminUser}
                intakeFormToSend={intakeFormToSend}
                setIntakeFormToSend={setIntakeFormToSend}
                intakeforms={adminUser?.intakeforms}
                openModal={isSendOpen}
                onCloseModal={() => {
                    setSelectedPayer(null)
                    setIsSendOpen(false)
                }}
                subdomain={subdomain}
            />}
        </div>
    </>);
};
export default PayersTable;
