import {
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_RESET_ERROR_STATE,
    FILE_UPLOAD_LOADING,
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_ERROR,
    TASK_SAVE_LOADING,
    TASK_SAVE_SUCCESS,
    TASK_SAVE_ERROR,
    TASK_DATA_LOADING,
    TASK_DATA_SUCCESS,
    TASK_DATA_ERROR,
    REGISTER_LOADING,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    SETTING_LOADING,
    SETTING_SUCCESS,
    SETTING_ERROR,
    FETCH_CURRENT_USER_SUCCESS,
    FETCH_CURRENT_USER_ERROR,
    FETCH_CURRENT_USER_LOADING,
    FETCH_USER_ACCOUNTS_SUCCESS,
    FETCH_USER_ACCOUNTS_ERROR,
    FETCH_USER_ACCOUNTS_LOADING,
    FETCH_GETALL_DATA_LOADING,
    FETCH_GETALL_DATA_SUCCESS,
    FETCH_GETALL_DATA_ERROR,
    FETCH_GETALLADMINS_LOADING,
    FETCH_GETALLADMINS_SUCCESS,
    FETCH_GETALLADMINS_ERROR,
    FETCH_ADMIN_USER_LOADING,
    FETCH_ADMIN_USER_SUCCESS,
    FETCH_ADMIN_USER_ERROR,
    FORGOT_EMAIL_LOADING,
    FORGOT_EMAIL_SUCCESS,
    FORGOT_EMAIL_ERROR,
    UPDATE_PASSWORD_ERROR,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_LOADING,
    CHECK_VALIDHASH_ERROR,
    CHECK_VALIDHASH_SUCCESS,
    CHECK_VALIDHASH_LOADING,
    UPDATE_EMAIL_ERROR,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_EMAIL_LOADING,
    CHECK_VALID_EMAIL_ERROR,
    CHECK_VALID_EMAIL_SUCCESS,
    CHECK_VALID_EMAIL_LOADING,
    FETCH_TRANSACTION_DATA_LOADING,
    FETCH_TRANSACTION_DATA_SUCCESS,
    FETCH_TRANSACTION_DATA_ERROR,
    GENERATE_ACCESS_CODE_ERROR,
    GENERATE_ACCESS_CODE_SUCCESS,
    GENERATE_ACCESS_CODE_LOADING,
    VERIFY_ACCESS_CODE_LOADING,
    VERIFY_ACCESS_CODE_SUCCESS,
    VERIFY_ACCESS_CODE_ERROR,
    LOGIN_ACCESSCODE_SUCCESS,
    LOGIN_ACCESSCODE_LOADING,
    LOGIN_ACCESSCODE_ERROR,
    VERIFY_LOGIN_ACCESSCODE_ERROR,
    VERIFY_LOGIN_ACCESSCODE_SUCCESS,
    VERIFY_LOGIN_ACCESSCODE_LOADING,
    UPDATEPASSWORD_LINK_ERROR,
    UPDATEPASSWORD_LINK_ERROR_RESET,
    UPDATEPASSWORD_LINK_SUCCESS,
    UPDATEPASSWORD_LINK_SUCCESS_RESET,
    UPDATEPASSWORD_LINK_LOADING,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR,
    UPLOAD_FILE_LOADING,
    ADMIN_LOGIN_ACCESSCODE_SUCCESS,
    ADMIN_LOGIN_ACCESSCODE_ERROR,
    ADMIN_LOGIN_ACCESSCODE_LOADING,
    FETCH_CURRENT_ADMIN_LOADING,
    FETCH_CURRENT_ADMIN_SUCCESS,
    FETCH_CURRENT_ADMIN_ERROR,
    ADMIN_UPDATE_PASSWORD_ERROR,
    ADMIN_UPDATE_PASSWORD_SUCCESS,
    ADMIN_UPDATE_PASSWORD_LOADING,
    ADMIN_CHECK_VALIDHASH_ERROR,
    ADMIN_CHECK_VALIDHASH_SUCCESS,
    ADMIN_CHECK_VALIDHASH_LOADING,
    ADMIN_PASSWORD_LOADING,
    ADMIN_PASSWORD_SUCCESS,
    ADMIN_PASSWORD_ERROR,
    PAYUSER_ERROR,
    PAYUSER_SUCCESS,
    PAYUSER_LOADING,
    PAYUSER_CLEAR,
    SEND_SMS_LOADING,
    SEND_SMS_SUCCESS,
    SEND_SMS_ERROR,
    UPLOAD_LOGO_FILE_LOADING,
    UPLOAD_LOGO_FILE_SUCCESS,
    UPLOAD_LOGO_FILE_ERROR,
    SAVE_TRANSACTION_SUCCESS,
    SAVE_TRANSACTION_ERROR,
    SAVE_TRANSACTION_LOADING,
    OFFICE_CONTACT_LOADING,
    OFFICE_CONTACT_SUCCESS,
    OFFICE_CONTACT_ERROR,
    DOWNLOAD_FILE_ERROR,
    DOWNLOAD_FILE_LOADING,
    DOWNLOAD_FILE_SUCCESS,
    UPDATE_ADMIN_DATA_SUCCESS,
    UPDATE_ADMIN_DATA_ERROR,
    UPDATE_ADMIN_DATA_LOADING,
    RESENDCODE_LOADING,
    RESENDCODE_SUCCESS,
    RESENDCODE_ERROR,
    LOGIN_ADMIN_ERROR,
    LOGIN_ADMIN_LOADING,
    LOGIN_ADMIN_SUCCESS,
    USER_SENDCODE_ERROR,
    USER_SENDCODE_SUCCESS,
    USER_SENDCODE_LOADING,
    GET_EMBEDDED_SIGN_SUCCESS,
    GET_EMBEDDED_SIGN_LOADING,
    GET_EMBEDDED_SIGN_ERROR,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_ERROR,
    GET_MESSAGE_LOADING,
    SAVE_HELLOSIGN_TEMPLATE_SUCCESS,
    SAVE_HELLOSIGN_TEMPLATE_LOADING,
    SAVE_HELLOSIGN_TEMPLATE_ERROR,
    GET_HELLOSIGN_TEMPLATE_SUCCESS,
    GET_HELLOSIGN_TEMPLATE_LOADING,
    GET_HELLOSIGN_TEMPLATE_ERROR,
    USER_CONTACT_LOADING,
    USER_CONTACT_SUCCESS,
    USER_CONTACT_ERROR,
    DELETE_MESSAGE_USER_ERROR,
    DELETE_MESSAGE_USER_SUCCESS,
    DELETE_MESSAGE_USER_LOADING,
    SHOW_USER_MESSAGE_LOADING,
    SHOW_USER_MESSAGE_SUCCESS,
    SHOW_USER_MESSAGE_ERROR,
    UPLOAD_USERFORM_LOADING,
    UPLOAD_USERFORM_SUCCESS,
    UPLOAD_USERFORM_ERROR,
    SET_USERFORMS,
    USERFORM_DELETE_ERROR,
    USERFORM_DELETE_SUCCESS,
    USERFORM_DELETE_LOADING,
    TRANSACTION_GET_LOADING,
    TRANSACTION_GET_SUCCESS,
    TRANSACTION_GET_ERROR,
    SEND_INTAKE_SUCCESS,
    SEND_INTAKE_LOADING,
    SEND_INTAKE_ERROR,
    FETCH_CURRENT_MESSAGES_LOADING,
    FETCH_CURRENT_MESSAGES_SUCCESS,
    FETCH_CURRENT_MESSAGES_ERROR,
    FETCH_URL_SUCCESS,
    FETCH_URL_ERROR,
    FETCH_URL_LOADING,
    NOTIFICATION_INTAKEFORM_LOADING,
    NOTIFICATION_INTAKEFORM_ERROR,
    NOTIFICATION_INTAKEFORM_SUCCESS,
    GET_USERFORM_ERROR,
    GET_USERFORM_LOADING,
    GET_USERFORM_SUCCESS,
    SEND_MESSAGE_SUCCESS_NOTIFY,
    MESSAGE_TO_ADMIN_ERROR,
    MESSAGE_TO_ADMIN_LOADING,
    MESSAGE_TO_ADMIN_SUCCESS,
    SET_TEMP_INTAKEFORM_ID,
    ACCOUNT_LOGOUT,
    TRANSACTION_IS_VOIDABLE_LOADING,
    TRANSACTION_IS_VOIDABLE_SUCCESS,
    TRANSACTION_IS_VOIDABLE_ERROR,
    TRANSACTION_REFUND_LOADING,
    TRANSACTION_REFUND_SUCCESS,
    TRANSACTION_REFUND_ERROR,
    DELETE_USERDATA_LOADING,
    DELETE_USERDATA_SUCCESS,
    DELETE_USERDATA_ERROR,
    DELETE_USERDATA_INITIAL,
    ADD_ADMIN_LOADING,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_ERROR,
    ADMIN_LOGIN_INVITE_LOADING,
    ADMIN_LOGIN_INVITE_SUCCESS,
    ADMIN_LOGIN_INVITE_ERROR,
    UPDATE_INTAKE_FORM_TYPE_SUCCESS,
    UPDATE_INTAKE_FORM_TYPE_LOADING,
    UPDATE_INTAKE_FORM_TYPE_ERROR,
} from "../../constants";

const initState = {
    alluser: [],
    alluserSearchResultCount: 0,
    alluserSearchResultPages: 0,
    alluserTotalCount: 0,
    alluserTotalPages: 0,

    isLoginLoad: false,
    isLoginError: false,
    isCalendarLoad: false,
    isCalendarError: false,
    calendar: {},
    userForms: [],
    isRegisterLoad: false,
    isRegisterError: false,
    registerPay: null,
    isSettingLoad: false,
    isSettingError: false,
    taskList: {},
    setting: {},
    user: {},
    isCurrentUserLoad: false,
    isCurrentUserError: false,
    isallUserLoad: false,
    isallUserError: false,
    allAdmins: [],
    isallAdminsLoad: false,
    isallAdminsError: false,
    isAccountsLoad: false,
    isAccountsError: false,
    accounts: [],
    accountTotals: 0,
    adminUser: {},
    isAdminUserLoad: false,
    isAdminUserSuccess: false,
    isAdminUserError: false,
    forgotEmail: [],
    isforgotEmailLoading: false,
    isforgotEmailSuccess: false,
    isUpdatePasswordLoading: false,
    isUpdatePasswordError: false,
    isCheckValidHashLoading: false,
    isCheckValidHashError: false,
    isUpdateEmailLoading: false,
    isUpdateEmailSuccess: false,
    isUpdateEmailError: false,
    isCheckValidEmailLoading: false,
    isCheckValidEmailSuccess: false,
    isCheckValidEmailError: false,
    isTransactionDataLoad: false,
    isTransactionDataError: false,
    transactionData: [],
    upload: {},
    uploadFileError: false,
    uploadFileSuccess: false,
    uploadFileLoading: false,
    isAdminLoginCodeSuccess: false,
    isAdminLoginCodeLoading: false,
    isAdminLoginCodeError: false,
    isCurrentAdminError: false,
    isCurrentAdminLoad: false,
    adminupdatePassError: false,
    adminupdatePassSuccess: false,
    adminupdatePassLoading: false,
    adminValidTokenError: false,
    adminValidTokenSuccess: undefined,
    adminValidTokenLoading: false,
    adminPasswordLoading: false,
    adminPasswordSuccess: false,
    adminPasswordError: false,
    payerUser: {},
    successMessage: "",
    transactionLoading: false,
    transactionSuccess: false,
    transactionError: false,
    officeContactLoading: false,
    officeContactSuccess: false,
    officeContactError: false,
    uploadLogoLoading: false,
    download: [],
    isVerifyLoginCodeSuccess: false,
    isVerifyLoginCodeLoading: false,
    isSendCodeSuccess: false,
    isSendCodeLoading: false,
    isResendLoading: false,
    isResendSuccess: false,
    isResendError: false,
    isLoginSuccess: false,
    isLoginAdminLoading: false,
    isLoginAdminSuccess: false,
    isLogiAdminError: false,
    isSendCodeError: false,
    getMessageSuccess: false,
    getMessageLoading: false,
    getMessageError: false,
    messages: [],
    saveHelloSignTemplateSuccess: false,
    saveHelloSignTemplateLoading: false,
    saveHelloSignTemplateError: false,
    getHelloSignTemplateSuccess: false,
    getHelloSignTemplateLoading: false,
    getHelloSignTemplateError: false,
    sendMessageSuccess: false,
    sendMessageLoading: false,
    sendMessageError: false,
    deleteMessageSuccess: false,
    deleteMessageLoading: false,
    deleteMessageError: false,
    getSignUrlLoading: false,
    getSignUrl: "",
    getSignUrlError: false,
    getSignUrlSuccess: true,
    isTransactionLoading: false,
    isTransactionSuccess: false,
    isTransactionError: false,
    tranasction: [],
    isSendIntakeSuccess: false,
    isSendIntakeError: false,
    isSendIntakeLoading: false,
    userCurrentMessages: [],
    isCurrentMessagesError: false,
    isCurrentMessagesSuccess: false,
    isCurrentMessagesLoading: false,
    logoUrl: "",
    logoFetchLoading: false,
    logoFetchSuccess: false,
    logoFetchError: false,
    intakeLoading: false,
    documentLoading: false,
    intakeNotifyLoading: false,
    intakeNotifySuccess: false,
    intakeNotifyError: false,
    userFormError: false,
    userFormSuccess: false,
    userFormLoading: false,
    formUser: [],
    messageToAdminError: false,
    messageToAdminSuccess: false,
    messageToAdminLoading: false,
    payerUsers: [],
    isAuthenticated: false,
    tempIntakeFormId: null,
    theme: [],
    transactionIsVoidableLoading: false,
    transactionIsVoidableSuccess: false,
    transactionIsVoidableError: false,
    transactionIsVoidableTxnId: '',
    transactionIsVoidableResult: undefined,
    transactionRefundLoading: false,
    transactionRefundSuccess: false,
    transactionRefundError: false,
    deleteUserDataLoading: false,
    deleteUserDataSuccess: false,
    deleteUserDataError: false,
    addAdminLoading: false,
    addAdminSuccess: false,
    addAdminError: false,
    updateIntakeFormTypeSuccess: false,
    updateIntakeFormTypeLoading: false,
    updateIntakeFormTypeError: false,
};

const authReducer = (state = initState, action) => {
    const {type, payload} = action || {};
    const setInitState = (obj = {}) => {
        return {...state, ...obj};
    };

    switch (type) {
        case SET_TEMP_INTAKEFORM_ID:
            return setInitState({
                tempIntakeFormId: payload,
            });
        case ACCOUNT_LOGOUT:
            return setInitState({
                isAuthenticated: false,
                user: {},
                adminUser: {},
                payerUser: {},
            });
        case LOGIN_LOADING:
            return setInitState({
                isLoginLoad: true,
                isLoginError: false,
                isLoginSuccess: false,
            });
        case LOGIN_SUCCESS:
            return setInitState({
                isLoginLoad: false,
                user: payload,
                isLoginSuccess: true,
            });
        case LOGIN_ERROR:
            return setInitState({
                isLoginError: true,
                isLoginLoad: false,
                isLoginSuccess: false,
            });
        case LOGIN_RESET_ERROR_STATE:
            return setInitState({
                isLoginError: false,
            });
        case FILE_UPLOAD_LOADING:
            return setInitState({
                isFileUploadLoad: true,
                isFileUploadError: false,
            });
        case FILE_UPLOAD_SUCCESS:
            return setInitState({
                fileUploadPay: payload,
                isFileUploadLoad: false,
            });
        case FILE_UPLOAD_ERROR:
            return setInitState({
                isFileUploadError: true,
                isFileUploadLoad: false,
            });
        case TASK_SAVE_LOADING:
            return setInitState({
                isTaskSaveLoad: true,
                isTaskSaveError: false,
            });
        case TASK_SAVE_SUCCESS:
            return setInitState({
                taskSavePay: payload,
                isTaskSaveLoad: false,
            });
        case TASK_SAVE_ERROR:
            return setInitState({
                isTaskSaveError: true,
                isTaskSaveLoad: false,
            });
        case SET_USERFORMS:
            return setInitState({
                userForms: payload,
            });
        case TASK_DATA_LOADING:
            return setInitState({
                isTaskListLoad: true,
                isTaskDataError: false,
            });
        case TASK_DATA_SUCCESS:
            return setInitState({
                taskList: payload,
                isTaskListLoad: false,
            });
        case TASK_DATA_ERROR:
            return setInitState({
                isTaskListError: true,
                isTaskListLoad: false,
            });
        case REGISTER_LOADING:
            return setInitState({
                isRegisterLoad: true,
                isRegisterError: false,
            });
        case REGISTER_SUCCESS:
            return setInitState({
                registerPay: payload,
                isRegisterLoad: false,
            });
        case REGISTER_ERROR:
            return setInitState({
                isRegisterError: true,
                isRegisterLoad: false,
            });
        case SETTING_LOADING:
            return setInitState({
                isSettingLoad: true,
                isSettingError: false,
            });
        case SETTING_SUCCESS:
            return setInitState({
                setting: payload,
                isSettingLoad: false,
            });
        case SETTING_ERROR:
            return setInitState({
                isSettingError: true,
                isSettingLoad: false,
            });

        case FETCH_CURRENT_USER_LOADING:
            return setInitState({
                isCurrentUserLoad: true,
                isCurrentUserError: false,
            });
        case FETCH_CURRENT_USER_SUCCESS:
            return setInitState({
                user: payload,
                isCurrentUserLoad: false,
            });
        case FETCH_CURRENT_USER_ERROR:
            return setInitState({
                isCurrentUserError: true,
                isCurrentUserLoad: false,
            });
        case FETCH_USER_ACCOUNTS_LOADING:
            return setInitState({
                isAccountsLoad: true,
                isAccountsError: false,
            });
        case FETCH_USER_ACCOUNTS_SUCCESS:
            return setInitState({
                accounts: payload.accounts,
                accountTotals: payload.totals,
                isAccountsLoad: false,
            });
        case FETCH_USER_ACCOUNTS_ERROR:
            return setInitState({
                isAccountsError: true,
                isAccountsLoad: false,
            });
        case FETCH_GETALL_DATA_LOADING:
            return setInitState({
                isallUserLoad: true,
                isallUserError: false,
            });
        case FETCH_GETALL_DATA_SUCCESS:
            return setInitState({
                alluser: payload.users,
                alluserTotalCount: payload.totalCount,
                alluserTotalPages: payload.totalPages,
                alluserSearchResultCount: payload.searchResultCount,
                alluserSearchResultPages: payload.searchResultPages,
                isallUserLoad: false,
            });
        case FETCH_GETALL_DATA_ERROR:
            return setInitState({
                isallUserLoad: true,
                isallUserError: false,
            });
        case FETCH_GETALLADMINS_ERROR:
            return setInitState({
                isallAdminsLoad: false,
                isallAdminsError: true,
            });
        case FETCH_GETALLADMINS_LOADING:
            return setInitState({
                isallAdminsLoad: true,
                isallAdminsError: false,
            });
        case FETCH_GETALLADMINS_SUCCESS:
            return setInitState({
                allAdmins: payload,
                isallAdminsLoad: false,
                isallAdminsError: false,
            });
        case FETCH_ADMIN_USER_LOADING:
            return setInitState({
                isAdminUserLoad: true,
                isAdminUserError: false,
            });
        case FETCH_ADMIN_USER_SUCCESS:
            return setInitState({
                adminUser: payload,
                isAuthenticated: true,
                isAdminUserLoad: false,
                isAdminUserSuccess: true,
            });
        case FETCH_ADMIN_USER_ERROR:
            return setInitState({
                isAdminUserError: true,
                isAdminUserLoad: false,
            });
        case FORGOT_EMAIL_LOADING:
            return setInitState({
                isforgotEmailLoading: true,
                isforgotEmailSuccess: false,
            });
        case FORGOT_EMAIL_SUCCESS:
            return setInitState({
                isforgotEmailLoading: false,
                isforgotEmailSuccess: true,
                forgotEmail: [],
            });
        case FORGOT_EMAIL_ERROR:
            return setInitState({
                isforgotEmailLoading: false,
                isforgotEmailSuccess: false,
            });
        case UPDATE_PASSWORD_ERROR:
            return setInitState({
                isUpdatePasswordError: true,
                isUpdatePasswordLoading: false,
                isUpdatePasswordSuccess: false,
            });
        case UPDATE_PASSWORD_SUCCESS:
            return setInitState({
                isUpdatePasswordError: false,
                isUpdatePasswordLoading: false,
                isUpdatePasswordSuccess: true,
            });
        case UPDATE_PASSWORD_LOADING:
            return setInitState({
                isUpdatePasswordError: false,
                isUpdatePasswordLoading: true,
            });
        case CHECK_VALIDHASH_LOADING:
            return setInitState({
                isCheckValidHashError: false,
                isCheckValidHashLoading: true,
            });
        case CHECK_VALIDHASH_ERROR:
            return setInitState({
                isCheckValidHashSuccess: false,
                isCheckValidHashError: true,
                isCheckValidHashLoading: false,
            });
        case CHECK_VALIDHASH_SUCCESS:
            return setInitState({
                user: payload.user,
                isCheckValidHashError: false,
                isCheckValidHashSuccess: true,
                isCheckValidHashLoading: false,
            });
        case UPDATE_EMAIL_ERROR:
            return setInitState({
                isUpdateEmailError: true,
                isUpdateEmailSuccess: false,
                isUpdateEmailLoading: false,
            });
        case UPDATE_EMAIL_SUCCESS:
            return setInitState({
                isUpdateEmailSuccess: true,
                isUpdateEmailError: false,
                isUpdateEmailLoading: false,
            });
        case UPDATE_EMAIL_LOADING:
            return setInitState({
                isUpdateEmailSuccess: false,
                isUpdateEmailError: false,
                isUpdateEmailLoading: true,
            });
        case CHECK_VALID_EMAIL_LOADING:
            return setInitState({
                isCheckValidEmailError: false,
                isCheckValidEmailLoading: true,
                isCheckValidEmailSuccess: false,
            });
        case CHECK_VALID_EMAIL_SUCCESS:
            return setInitState({
                isCheckValidEmailError: false,
                isCheckValidEmailLoading: false,
                isCheckValidEmailSuccess: true,
            });
        case CHECK_VALID_EMAIL_ERROR:
            return setInitState({
                isCheckValidEmailError: true,
                isCheckValidEmailLoading: false,
                isCheckValidEmailSuccess: false,
            });

        case FETCH_TRANSACTION_DATA_LOADING:
            return setInitState({
                isTransactionDataLoad: true,
                isTransactionDataError: false
            });
        case FETCH_TRANSACTION_DATA_SUCCESS:
            return setInitState({
                transactionData: payload,
                isTransactionDataLoad: false,
            });
        case FETCH_TRANSACTION_DATA_ERROR:
            return setInitState({
                isTransactionDataLoad: true,
                isTransactionDataError: false,
            });
        case GENERATE_ACCESS_CODE_ERROR:
            return setInitState({
                isGenerateAccessCodeError: true,
                isGenerateAccessCodeSuccess: false,
                isGenerateAccessCodeLoading: false,
            });
        case GENERATE_ACCESS_CODE_SUCCESS:
            return setInitState({
                isGenerateAccessCodeError: false,
                isGenerateAccessCodeLoading: false,
                isGenerateAccessCodeSuccess: true,
            });
        case GENERATE_ACCESS_CODE_LOADING:
            return setInitState({
                isGenerateAccessCodeError: false,
                isGenerateAccessCodeLoading: true,
                isGenerateAccessCodeSuccess: false,
            });
        case VERIFY_ACCESS_CODE_LOADING:
            return setInitState({
                isVerifyAccessCodeError: false,
                isVerifyAccessCodeLoading: true,
            });
        case VERIFY_ACCESS_CODE_SUCCESS:
            return setInitState({
                isAuthenticated: true,
                isVerifyAccessCodeError: false,
                isVerifyAccessCodeSuccess: true,
                isVerifyAccessCodeLoading: false,
            });
        case VERIFY_ACCESS_CODE_ERROR:
            return setInitState({
                isVerifyAccessCodeError: true,
                isVerifyAccessCodeSuccess: false,
                isVerifyAccessCodeLoading: false,
            });
        case LOGIN_ACCESSCODE_SUCCESS:
            return setInitState({
                successMessage: payload,
                isLoginCodeSuccess: true,
                isLoginCodeLoading: false,
                isLoginCodeError: false,
            });
        case LOGIN_ACCESSCODE_ERROR:
            return setInitState({
                isLoginCodeError: true,
                isLoginCodeSuccess: false,
            });
        case LOGIN_ACCESSCODE_LOADING:
            return setInitState({
                isLoginCodeLoading: true,
                isLoginCodeSuccess: false,
            });
        case VERIFY_LOGIN_ACCESSCODE_ERROR:
            return setInitState({
                isVerifyLoginCodeError: true,
                isVerifyLoginCodeSuccess: false,
                isVerifyLoginCodeLoading: false,
            });
        case VERIFY_LOGIN_ACCESSCODE_SUCCESS:
            return setInitState({
                adminUser: payload,
                isAuthenticated: true,
                isVerifyLoginCodeSuccess: true,
                isVerifyLoginCodeError: false,
                isVerifyLoginCodeLoading: false,
            });
        case VERIFY_LOGIN_ACCESSCODE_LOADING:
            return setInitState({
                isVerifyLoginCodeLoading: true,
                isVerifyLoginCodeError: false,
                isVerifyLoginCodeSuccess: false,
            });
        case UPDATEPASSWORD_LINK_LOADING:
            return setInitState({
                updateLinkLoading: true,
                updateLinkError: false,
                updateLinkSuccess: false,
            });
        case UPDATEPASSWORD_LINK_SUCCESS:
            return setInitState({
                updateLinkLoading: false,
                updateLinkSuccess: true,
                updateLinkError: false,
            });
        case UPDATEPASSWORD_LINK_ERROR:
            return setInitState({
                updateLinkLoading: false,
                updateLinkSuccess: false,
                updateLinkError: true,
            });
        case UPDATEPASSWORD_LINK_ERROR_RESET:
            return setInitState({
                updateLinkError: false,
            });
        case UPDATEPASSWORD_LINK_SUCCESS_RESET:
            return setInitState({
                updateLinkSuccess: false,
            });
        case UPLOAD_FILE_LOADING:
            return setInitState({
                intakeLoading: payload === "intake",
                documentLoading: payload === "document",
                uploadFileLoading: true,
                uploadFileError: false,
            });
        case UPLOAD_FILE_SUCCESS:
            return setInitState({
                upload: payload,
                uploadFileLoading: false,
                uploadFileSuccess: true,
                intakeLoading: false,
                documentLoading: false,
            });
        case UPLOAD_FILE_ERROR:
            return setInitState({
                uploadFileError: true,
                uploadFileSuccess: false,
                uploadFileLoading: false,
                intakeLoading: false,
                documentLoading: false,
            });
        case UPLOAD_USERFORM_LOADING:
            return setInitState({
                uploadUserFormLoading: true,
                uploadUserFormSuccess: false,
                uploadUserFormError: false,
            });
        case UPLOAD_USERFORM_SUCCESS:
            return setInitState({
                uploadUserFormLoading: false,
                uploadUserFormSuccess: true,
                uploadUserFormError: false,
            });
        case UPLOAD_USERFORM_ERROR:
            return setInitState({
                uploadUserFormLoading: false,
                uploadUserFormSuccess: false,
                uploadUserFormError: true,
            });
        case ADMIN_LOGIN_ACCESSCODE_SUCCESS:
            return setInitState({
                successMessage: payload,
                isAdminLoginCodeSuccess: true,
                isAdminLoginCodeLoading: false,
                isAdminLoginCodeError: false,
            });
        case ADMIN_LOGIN_ACCESSCODE_ERROR:
            return setInitState({
                isAdminLoginCodeError: true,
                isAdminLoginCodeSuccess: false,
            });
        case ADMIN_LOGIN_ACCESSCODE_LOADING:
            return setInitState({
                isAdminLoginCodeLoading: true,
                isAdminLoginCodeSuccess: false,
            });

        case FETCH_CURRENT_ADMIN_LOADING:
            return setInitState({
                isCurrentAdminLoad: true,
                isCurrentAdminError: false,
            });
        case FETCH_CURRENT_ADMIN_SUCCESS:
            return setInitState({
                user: payload,
                isCurrentAdminLoad: false,
            });
        case FETCH_CURRENT_ADMIN_ERROR:
            return setInitState({
                isCurrentAdminError: true,
                isCurrentAdminLoad: false,
            });
        case ADMIN_UPDATE_PASSWORD_ERROR:
            return setInitState({
                adminupdatePassError: true,
                adminupdatePassLoading: false,
                adminupdatePassSuccess: false,
            });
        case ADMIN_UPDATE_PASSWORD_SUCCESS:
            return setInitState({
                adminupdatePassError: false,
                adminupdatePassLoading: false,
                adminupdatePassSuccess: true,
            });

        case ADMIN_UPDATE_PASSWORD_LOADING:
            return setInitState({
                adminupdatePassLoading: true,
                adminupdatePassError: false,
                adminupdatePassSuccess: false,
            });
        case ADMIN_CHECK_VALIDHASH_ERROR:
            return setInitState({
                adminValidTokenError: true,
                adminValidTokenSuccess: false,
            });
        case ADMIN_CHECK_VALIDHASH_SUCCESS:
            return setInitState({
                adminValidTokenError: false,
                adminValidTokenSuccess: true,
            });
        case ADMIN_CHECK_VALIDHASH_LOADING:
            return setInitState({
                adminValidTokenLoading: true,
                adminValidTokenError: false,
            });
        case ADMIN_PASSWORD_LOADING:
            return setInitState({
                adminPasswordLoading: true,
                adminPasswordError: false,
            });
        case ADMIN_PASSWORD_SUCCESS:
            return setInitState({
                adminPasswordSuccess: true,
                adminPasswordError: false,
                adminPasswordLoading: false,
            });
        case ADMIN_PASSWORD_ERROR:
            return setInitState({
                adminPasswordSuccess: false,
                adminPasswordError: true,
                adminPasswordLoading: false,
            });
        case PAYUSER_SUCCESS:
            return setInitState({
                payerUser: payload,
                isPayerLoading: false,
            });
        case PAYUSER_LOADING:
            return setInitState({
                isPayerLoading: true,
            });
        case PAYUSER_ERROR:
            return setInitState({
                isPayerLoading: false,
            });
        case PAYUSER_CLEAR:
            return setInitState({
                payerUser: payload,
                isPayerLoading: false,
            });
        case SEND_SMS_LOADING:
            return setInitState({
                isSendCodeLoading: true,
                isSendCodeSuccess: false,
            });
        case SEND_SMS_SUCCESS:
            return setInitState({
                isSendCodeLoading: false,
                isSendCodeSuccess: true,
            });
        case SEND_SMS_ERROR:
            return setInitState({
                isSendCodeLoading: false,
                isSendCodeSuccess: false,
            });
        case UPLOAD_LOGO_FILE_LOADING:
            return setInitState({
                uploadLogoLoading: true,
                uploadLogoeEror: false,
            });
        case UPLOAD_LOGO_FILE_SUCCESS:
            return setInitState({
                logo: payload.logoUrl,
                adminUser: payload.sosCustomer,
                uploadLogoLoading: false,
                uploadLogoSuccess: true,
                uploadLogoeEror: false,
            });
        case UPLOAD_LOGO_FILE_ERROR:
            return setInitState({
                uploadLogoLoading: false,
                uploadLogoSuccess: false,
                uploadLogoeEror: true,
            });
        case SAVE_TRANSACTION_LOADING:
            return setInitState({
                transactionLoading: true,
                transactionError: false,
            });
        case SAVE_TRANSACTION_SUCCESS:
            return setInitState({
                transactionLoading: false,
                transactionSuccess: true,
                transactionError: false,
            });
        case SAVE_TRANSACTION_ERROR:
            return setInitState({
                transactionLoading: false,
                transactionSuccess: false,
                transactionError: true,
            });

        case OFFICE_CONTACT_LOADING:
            return setInitState({
                officeContactLoading: true,
                officeContactEror: false,
            });
        case OFFICE_CONTACT_SUCCESS:
            return setInitState({
                messages: payload,
                officeContactLoading: false,
                officeContactSuccess: true,
                officeContactError: false,
            });
        case OFFICE_CONTACT_ERROR:
            return setInitState({
                officeContactLoading: false,
                officeContactSuccess: false,
                officeContactError: true,
            });
        case DOWNLOAD_FILE_SUCCESS:
            return setInitState({
                download: payload,
                isDownloadSuccess: true,
                isDownloadLoading: false,
            });
        case DOWNLOAD_FILE_ERROR:
            return setInitState({
                isDownloadError: true,
                isDownloadSuccess: false,
            });
        case DOWNLOAD_FILE_LOADING:
            return setInitState({
                isDownloadError: false,
                isDownloadLoading: true,
            });
        case UPDATE_ADMIN_DATA_SUCCESS:
            return setInitState({
                adminUser: payload,
                isUpdateAdminSuccess: true,
                isUpdateAdminLoading: false,
                isUpdateAdminError: false,
            });
        case UPDATE_ADMIN_DATA_ERROR:
            return setInitState({
                isUpdateAdminSuccess: false,
                isUpdateAdminError: true,
                isUpdateAdminLoading: false,
            });
        case UPDATE_ADMIN_DATA_LOADING:
            return setInitState({
                isUpdateAdminSuccess: false,
                isUpdateAdminError: false,
                isUpdateAdminLoading: true,
            });
        case RESENDCODE_LOADING:
            return setInitState({
                isResendLoading: true,
                isResendSuccess: false,
                isResendError: false,
            });
        case RESENDCODE_SUCCESS:
            return setInitState({
                isResendLoading: false,
                isResendSuccess: true,
                isResendError: false,
            });
        case RESENDCODE_ERROR:
            return setInitState({
                isResendLoading: false,
                isResendSuccess: false,
                isResendError: false,
            });
        case LOGIN_ADMIN_LOADING:
            return setInitState({
                isLoginAdminLoading: true,
                isLoginAdminSuccess: false,
                isLogiAdminError: false,
            });
        case LOGIN_ADMIN_SUCCESS:
            return setInitState({
                adminUser: payload,
                isLoginAdminLoading: false,
                isLoginAdminSuccess: true,
                isLogiAdminError: false,
            });
        case LOGIN_ADMIN_ERROR:
            return setInitState({
                isLoginAdminLoading: false,
                isLoginAdminSuccess: false,
                isLogiAdminError: true,
            });
        case USER_SENDCODE_LOADING:
            return setInitState({
                isSendCodeSuccess: false,
                isSendCodeLoading: true,
                isSendCodeError: false,
            });
        case USER_SENDCODE_SUCCESS:
            return setInitState({
                isSendCodeSuccess: true,
                isSendCodeLoading: false,
                isSendCodeError: false,
            });
        case USER_SENDCODE_ERROR:
            return setInitState({
                isSendCodeSuccess: false,
                isSendCodeLoading: false,
                isSendCodeError: true,
            });
        case GET_EMBEDDED_SIGN_SUCCESS:
            return setInitState({
                embeddedHelloSignUrl: payload,
                isEmbeddedHelloSignUrlSuccess: true,
                isEmbeddedHelloSignUrlLoading: false,
                isEmbeddedHelloSignUrlError: false,
            });
        case GET_EMBEDDED_SIGN_LOADING:
            return setInitState({
                isEmbeddedHelloSignUrlSuccess: false,
                isEmbeddedHelloSignUrlLoading: true,
                isEmbeddedHelloSignUrlError: false,
            });
        case GET_EMBEDDED_SIGN_ERROR:
            return setInitState({
                isEmbeddedHelloSignUrlSuccess: false,
                isEmbeddedHelloSignUrlLoading: false,
                isEmbeddedHelloSignUrlError: true,
            });
        case SAVE_HELLOSIGN_TEMPLATE_SUCCESS:
            return setInitState({
                saveHelloSignTemplateSuccess: true,
                saveHelloSignTemplateLoading: false,
                saveHelloSignTemplateError: false,
            });
        case SAVE_HELLOSIGN_TEMPLATE_LOADING:
            return setInitState({
                saveHelloSignTemplateSuccess: false,
                saveHelloSignTemplateLoading: true,
                saveHelloSignTemplateError: false,
            });
        case SAVE_HELLOSIGN_TEMPLATE_ERROR:
            return setInitState({
                saveHelloSignTemplateSuccess: false,
                saveHelloSignTemplateLoading: false,
                saveHelloSignTemplateError: true,
            });

        case GET_HELLOSIGN_TEMPLATE_SUCCESS:
            return setInitState({
                getHelloSignTemplateSuccess: true,
                getHelloSignTemplateLoading: false,
                getHelloSignTemplateError: false,
            });
        case GET_HELLOSIGN_TEMPLATE_LOADING:
            return setInitState({
                getHelloSignTemplateSuccess: false,
                getHelloSignTemplateLoading: true,
                getHelloSignTemplateError: false,
            });
        case GET_HELLOSIGN_TEMPLATE_ERROR:
            return setInitState({
                getHelloSignTemplateSuccess: false,
                getHelloSignTemplateLoading: false,
                getHelloSignTemplateError: true,
            });

        case GET_MESSAGE_LOADING:
            return setInitState({
                getMessageSuccess: false,
                getMessageLoading: true,
                getMessageError: false,
            });
        case GET_MESSAGE_SUCCESS:
            return setInitState({
                messages: payload,
                getMessageSuccess: true,
                getMessageLoading: false,
                getMessageError: false,
            });
        case GET_MESSAGE_ERROR:
            return setInitState({
                getMessageSuccess: false,
                getMessageLoading: false,
                getMessageError: true,
            });

        case USER_CONTACT_LOADING:
            return setInitState({
                sendMessageSuccess: false,
                sendMessageLoading: true,
                sendMessageError: false,
            });
        case USER_CONTACT_SUCCESS:
            return setInitState({
                messages: payload,
                sendMessageSuccess: true,
                sendMessageLoading: false,
                sendMessageError: false,
            });
        case USER_CONTACT_ERROR:
            return setInitState({
                sendMessageSuccess: false,
                sendMessageLoading: false,
                sendMessageError: true,
            });
        case DELETE_MESSAGE_USER_SUCCESS:
            return setInitState({
                deleteMessageSuccess: true,
                deleteMessageLoading: false,
                deleteMessageError: false,
            });
        case DELETE_MESSAGE_USER_LOADING:
            return setInitState({
                deleteMessageSuccess: false,
                deleteMessageLoading: true,
                deleteMessageError: false,
            });
        case DELETE_MESSAGE_USER_ERROR:
            return setInitState({
                deleteMessageSuccess: false,
                deleteMessageLoading: false,
                deleteMessageError: true,
            });
        case SHOW_USER_MESSAGE_LOADING:
            return setInitState({
                userMessageSuccess: false,
                userMessageLoading: true,
                userMessageError: false,
            });
        case SHOW_USER_MESSAGE_SUCCESS:
            return setInitState({
                messages: payload,
                userMessageSuccess: true,
                userMessageLoading: false,
                userMessageError: false,
            });
        case SHOW_USER_MESSAGE_ERROR:
            return setInitState({
                userMessageSuccess: false,
                userMessageLoading: false,
                userMessageError: true,
            });
        case USERFORM_DELETE_LOADING:
            return setInitState({
                userFormLoading: true,
                userFormSuccess: false,
                userFormError: false,
            });
        case USERFORM_DELETE_ERROR:
            return setInitState({
                userFormLoading: false,
                userFormSuccess: false,
                userFormError: true,
            });
        case USERFORM_DELETE_SUCCESS:
            return setInitState({
                userFormLoading: false,
                userFormSuccess: true,
                userFormError: false,
            });
        case TRANSACTION_GET_LOADING:
            return setInitState({
                isTransactionError: false,
                isTransactionSuccess: false,
                isTransactionLoading: true,
                transactionRefundSuccess: false,
                transactionRefundError: false,
                transactionIdRefund: null,
                transactionIsVoidableSuccess: false,
                transactionIsVoidableError: false,
                transactionIsVoidableTxnId: null,
                transactionIsVoidable: undefined,
            });
        case TRANSACTION_GET_SUCCESS:
            return setInitState({
                transactionData: payload,
                isTransactionError: false,
                isTransactionSuccess: true,
                isTransactionLoading: false,
            });
        case TRANSACTION_GET_ERROR:
            return setInitState({
                isTransactionError: true,
                isTransactionSuccess: false,
                isTransactionLoading: false,
            });
        case SEND_INTAKE_SUCCESS:
            return setInitState({
                isSendIntakeError: false,
                isSendIntakeSuccess: true,
                isSendIntakeLoading: false,
            });
        case SEND_INTAKE_LOADING:
            return setInitState({
                isSendIntakeLoading: true,
                isSendIntakeError: false,
                isSendIntakeSuccess: false,
            });
        case SEND_INTAKE_ERROR:
            return setInitState({
                isSendIntakeLoading: false,
                isSendIntakeSuccess: false,
                isSendIntakeError: true,
            });
        case FETCH_CURRENT_MESSAGES_SUCCESS:
            return setInitState({
                isCurrentMessagesError: false,
                userCurrentMessages: payload,
                isCurrentMessagesSuccess: true,
                isCurrentMessagesLoading: false,
            });
        case FETCH_CURRENT_MESSAGES_LOADING:
            return setInitState({
                isCurrentMessagesLoading: true,
                isCurrentMessagesError: false,
                isCurrentMessagesSuccess: false,
            });
        case FETCH_CURRENT_MESSAGES_ERROR:
            return setInitState({
                isCurrentMessagesLoading: false,
                isCurrentMessagesSuccess: false,
                isCurrentMessagesError: true,
            });
        case FETCH_URL_LOADING:
            return setInitState({
                logoFetchLoading: true,
                logoFetchSuccess: false,
                logoFetchError: false,
            });
        case FETCH_URL_SUCCESS:
            return setInitState({
                logoFetchLoading: false,
                logoFetchSuccess: true,
                logoFetchError: false,
                logoUrl: payload.logoUrl,
                theme: payload.theme,
            });
        case FETCH_URL_ERROR:
            return setInitState({
                logoFetchLoading: false,
                logoFetchSuccess: false,
                logoFetchError: true,
            });
        case NOTIFICATION_INTAKEFORM_SUCCESS:
            return setInitState({
                intakeNotifyLoading: false,
                intakeNotifySuccess: true,
                intakeNotifyError: false,
            });
        case NOTIFICATION_INTAKEFORM_LOADING:
            return setInitState({
                intakeNotifyLoading: true,
                intakeNotifySuccess: false,
                intakeNotifyError: false,
            });
        case NOTIFICATION_INTAKEFORM_ERROR:
            return setInitState({
                intakeNotifyLoading: false,
                intakeNotifySuccess: false,
                intakeNotifyError: true,
            });
        case GET_USERFORM_ERROR:
            return setInitState({
                userFormError: true,
                userFormSuccess: false,
                userFormLoading: false,
            });
        case GET_USERFORM_LOADING:
            return setInitState({
                userFormError: false,
                userFormSuccess: false,
                userFormLoading: true,
            });
        case GET_USERFORM_SUCCESS:
            return setInitState({
                userFormError: false,
                userFormSuccess: true,
                userFormLoading: false,
                formUser: payload,
            });
        case SEND_MESSAGE_SUCCESS_NOTIFY:
            return setInitState({
                sendMessageSuccess: false,
                messageToAdminSuccess: false,
            });
        case MESSAGE_TO_ADMIN_SUCCESS:
            return setInitState({
                messageToAdminError: false,
                messageToAdminSuccess: true,
                messageToAdminLoading: false,
            });
        case MESSAGE_TO_ADMIN_LOADING:
            return setInitState({
                messageToAdminError: false,
                messageToAdminSuccess: false,
                messageToAdminLoading: true,
            });
        case MESSAGE_TO_ADMIN_ERROR:
            return setInitState({
                messageToAdminError: true,
                messageToAdminSuccess: false,
                messageToAdminLoading: false,
            });
        case TRANSACTION_IS_VOIDABLE_LOADING:
            return setInitState({
                transactionIsVoidableLoading: true,
                transactionIsVoidableSuccess: false,
                transactionIsVoidableError: false,
                transactionIsVoidableTxnId: payload,
                transactionIsVoidable: undefined,
            });
        case TRANSACTION_IS_VOIDABLE_SUCCESS:
            return setInitState({
                transactionIsVoidableLoading: false,
                transactionIsVoidableSuccess: true,
                transactionIsVoidableError: false,
                // transactionIsVoidableTxnId: undefined,
                transactionIsVoidable: payload,
            });
        case TRANSACTION_IS_VOIDABLE_ERROR:
            return setInitState({
                transactionIsVoidableLoading: false,
                transactionIsVoidableSuccess: false,
                transactionIsVoidableError: true,
                // transactionIsVoidableTxnId: undefined,
                transactionIsVoidable: undefined,
            });
        case TRANSACTION_REFUND_LOADING:
            return setInitState({
                transactionRefundLoading: true,
                transactionRefundSuccess: false,
                transactionRefundError: false,
                transactionIdRefund: payload
            });
        case TRANSACTION_REFUND_SUCCESS:
            return setInitState({
                transactionRefundLoading: false,
                transactionRefundSuccess: true,
                transactionRefundError: false
                // transactionRefund: true,
            });
        case TRANSACTION_REFUND_ERROR:
            return setInitState({
                transactionRefundLoading: false,
                transactionRefundSuccess: false,
                transactionRefundError: true,
            });

        case DELETE_USERDATA_LOADING:
            return setInitState({
                deleteUserDataLoading: true,
                deleteUserDataSuccess: false,
                deleteUserDataError: false,
            });

        case DELETE_USERDATA_SUCCESS:
            return setInitState({
                deleteUserDataLoading: false,
                deleteUserDataSuccess: true,
                deleteUserDataError: false,
            });

        case DELETE_USERDATA_ERROR:
            return setInitState({
                deleteUserDataLoading: false,
                deleteUserDataSuccess: false,
                deleteUserDataError: true,
            });

        case DELETE_USERDATA_INITIAL:
            return setInitState({
                deleteUserDataLoading: false,
                deleteUserDataSuccess: false,
                deleteUserDataError: false,
            });

        case ADD_ADMIN_LOADING:
            return setInitState({
                addAdminLoading: true,
                addAdminSuccess: false,
                addAdminError: false,
            });

        case ADD_ADMIN_SUCCESS:
            return setInitState({
                addAdminLoading: false,
                addAdminSuccess: true,
                addAdminError: false,
            });

        case ADD_ADMIN_ERROR:
            return setInitState({
                addAdminLoading: false,
                addAdminSuccess: false,
                addAdminError: true,
            });

        case ADMIN_LOGIN_INVITE_LOADING:
            return setInitState({
                adminLoginInviteLoading: true,
                adminLoginInviteSuccess: false,
                adminLoginInviteError: false,
            });

        case ADMIN_LOGIN_INVITE_SUCCESS:
            return setInitState({
                adminLoginInviteLoading: false,
                adminLoginInviteSuccess: true,
                adminLoginInviteError: false,
            });

        case ADMIN_LOGIN_INVITE_ERROR:
            return setInitState({
                adminLoginInviteLoading: false,
                adminLoginInviteSuccess: false,
                adminLoginInviteError: true,
            });

        case UPDATE_INTAKE_FORM_TYPE_LOADING:
            return setInitState({
                updateIntakeFormTypeSuccess: false,
                updateIntakeFormTypeLoading: true,
                updateIntakeFormTypeError: false,
            });

        case UPDATE_INTAKE_FORM_TYPE_SUCCESS:
            return setInitState({
                updateIntakeFormTypeSuccess: true,
                updateIntakeFormTypeLoading: false,
                updateIntakeFormTypeError: false,
            });

        case UPDATE_INTAKE_FORM_TYPE_ERROR:
            return setInitState({
                updateIntakeFormTypeSuccess: false,
                updateIntakeFormTypeLoading: false,
                updateIntakeFormTypeError: true,
            });
        default:
            return state;
    }
};

export default authReducer;
