import React from "react";
import {Modal} from "react-bootstrap";
import {CrossIcon} from "../../Components/Icons";
import "./payments.css";
import moment from "moment/moment";

const PaymentSuccessPopup = (props) => {
    const {openModal, onCloseModal, paymentMsg, officeName} = props;
    const print = (transaction) => {
        const docDefinition = {
            content: [
                {text: `Receipt for ${officeName}`, style: 'header'},
                ' ',
                ...transaction.patientPayer.map(o => {
                    return {
                        columns: [
                            {text: 'Patient:', width: '20%'},
                            {text: o.patientFirst + ' ' + o.patientLast + '(#' + o.patientNumber + ')', width: '80%'}
                        ],
                        style: 'column'
                    }
                }),
                {
                    columns: [
                        {text: 'Date:', width: '20%'},
                        {text: moment(transaction.createdAt).format('MM/DD/YYYY hh:mm a'), width: '80%'}
                    ],
                    style: 'column'
                },
                {
                    columns: [
                        {text: transaction.transactionsData.ssl_card_short_description + ':', width: '20%'},
                        {text: "xxxx" + transaction.transactionsData.ssl_card_number.slice(-4), width: '80%'}
                    ],
                    style: 'column'
                },
                {
                    columns: [
                        {text: 'Amount Paid:', width: '20%'},
                        {text: '$' + transaction.total, width: '80%'}
                    ],
                    style: 'column'
                },
                ' ',
                {text: 'Transaction ID:', style: 'subheader'},
                transaction.transactionId
            ],
            styles: {
                header: {
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                column: {
                    fontSize: 14,
                    margin: [0, 5, 0, 0]
                }
            }
        };

        pdfMake.createPdf(docDefinition).print();
    }

    const modalResponse = () => {
        if (paymentMsg === "successful") {
            let pendingTransaction = localStorage.getItem('PENDING_TRANSACTION');
            if (pendingTransaction) {
                const transaction = JSON.parse(pendingTransaction);
                return (<>
                    <h4>Payment Successful!</h4>
                    {transaction && transaction.payerEmail && !transaction.payerEmail.includes("*fake*") && <p>Receipt emailed to {transaction.payerEmail}</p>}
                    <div className={'justify-center-text gap-20'}>
                        <button className="sos-custom-btn  sos-custom-btn-css" onClick={() => {
                            localStorage.removeItem('PENDING_TRANSACTION');
                            onCloseModal();
                        }}>Ok
                        </button>
                        <button className="sos-custom-btn  sos-custom-btn-css" onClick={() => print(transaction)}>Print
                            Receipt
                        </button>
                    </div>

                </>)
            } else {
                return (<>
                    <h4>Payment Successful!</h4>
                    <p>Thank you for your payment</p>
                </>)
            }
        } else if (paymentMsg === "addSuccessful") {
            return (<>
                <h4>Payment method added successfully</h4>
            </>)
        } else if (paymentMsg === "editSuccessful") {
            return (<>
                <h4>Payment method updated successfully</h4>
            </>)
        } else {
            return (

                <>
                    <h4 dangerouslySetInnerHTML={{__html: (paymentMsg ? paymentMsg.split('.').join('<br/><br/>') : '')}}></h4>
                </>)
        }
    }

    return (<Modal show={openModal} size="md" centered className="success-payment">
        <div onClick={onCloseModal} className="close-modal-btn">
            <CrossIcon/>
        </div>
        <Modal.Body>
            <div className="modal-headr-text">
                {modalResponse()}
            </div>
        </Modal.Body>
    </Modal>)
};


export default PaymentSuccessPopup;
