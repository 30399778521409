import React, {useEffect, useState} from "react";
import {
    useReactTable, getCoreRowModel, getPaginationRowModel, getSortedRowModel, flexRender
} from "@tanstack/react-table";
import axios from "axios";
import "./../ReactTable/reacttable.css";
import './reacttable.css'

const ReactTableServerSide = ({
                                  fetchData,
                                  columns,
                                  colWidths,
                                  searchPlaceholder,
                                  initialSortState,

                              }) => {
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 20});
    const [sorting, setSorting] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        console.dir('sorting #slkdjfsdf');
        console.dir(sorting);
    },[sorting]);


    const handleChangeSearchQuery = (value) => {
        const clearBtn = document.getElementById('clearButton');
        if (value) {
            clearBtn.style.display = "block";
        } else {
            clearBtn.style.display = "none";
        }
    };

    const loadData = async () => {
        setLoading(true);
        const sortBy = sorting.length ? sorting[0].id : "";
        const sortOrder = sorting.length && sorting[0].desc ? "desc" : "asc";
        const {data, total} = await fetchData({
            currentPage: pagination.pageIndex + 1,
            limit: pagination.pageSize,
            sortBy,
            sortDirection: sortOrder,
            searchTerm: searchQuery,
        });
        setData(data);
        setTotalRows(total);
        setLoading(false);
    };

    useEffect(() => {
        loadData();
    }, [pagination, sorting]);

    // const columns = [{accessorKey: "id", header: "ID"}, {accessorKey: "name", header: "Name"}, {
    //     accessorKey: "email",
    //     header: "Email"
    // }, {accessorKey: "phone", header: "Phone"},];

    const table = useReactTable({
        data,
        columns,
        colWidths,
        pageCount: Math.ceil(totalRows / pagination.pageSize),
        manualPagination: true,
        manualSorting: true,
        state: {pagination, sorting},
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    function clearSearch() {
        setSearchQuery('');
        // const searchInpt = document.getElementById('searchInput');
        // searchInpt.focus();
        handleChangeSearchQuery('');
    }

    const useColWidths = colWidths && colWidths.length && colWidths.length >= columns.length;

    return (
        // <div className="container mt-4">
        <div
            // className="mt-4"
            className="react-table table-font-size table-responsive"
        >
            {/* Search Bar */}
            <form onSubmit={(e) => {
                e.preventDefault();
                loadData()
            }}>
                <div
                    style={{
                        display: 'flex',
                        gap: '2em',
                    }}
                    className='mb-3'
                >
                    <div className='search-container'>
                        <input
                            id="searchInput"
                            type="text"
                            className="form-control search-input"
                            placeholder={searchPlaceholder}
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                handleChangeSearchQuery(e.target.value);
                            }}
                            style={{
                                width: `${Math.floor(searchPlaceholder.length * 0.9)}ch`
                            }}
                        />
                        <button
                            type="button"
                            className="clear-button"
                            id="clearButton"
                            onClick={clearSearch}>×
                        </button>
                    </div>
                    <button
                        id="searchSubmit"
                        type="submit"
                        className="sos-custom-btn ml-4 sos-custom-btn-css sos-secondary-btn-padding ml-3"
                        style={{
                            height: "50px"
                        }}
                    >Search
                    </button>
                </div>
            </form>

            <table
                // className="table table-bordered"
                className="zui-table zui-table-rounded table-striped table-hover table-sm"
                style={{tableLayout: (colWidths && colWidths.length === columns.length) ? "fixed" : "auto"}}
            >
                <thead
                    // className="table-dark" bootstrap
                    className="tableheader"
                >
                <tr>
                    {table.getFlatHeaders().map((header, colIndex) => {
                        return (
                            <th key={header.id}
                                style={{
                                    fontSize: "1.4em",
                                    fontWeight: "400",
                                    // boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.3)", /* Shadow effect */
                                    backgroundColor: "#FFF",
                                    cursor: "pointer",
                                    verticalAlign: "baseline",
                                    width: useColWidths && colWidths[colIndex],
                                    // ...column.colStyle,
                                }}
                                onClick={() => {
                                    header.column.columnDef.enableSorting && header.column.toggleSorting();
                                }}>
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {header.column.getIsSorted() === "desc" ? " 🔽" : header.column.getIsSorted() === "asc" ? " 🔼" : ""}
                            </th>)
                    })}
                </tr>
                </thead>
                <tbody
                    className="react-table"
                >
                {loading ? (<tr>
                    <td
                        colSpan={columns.length}
                        className="text-center">
                        <div className="spinner-border text-primary" role="status"></div>
                    </td>
                </tr>) : (table.getRowModel().rows.map((row, rowIndex) => (
                    <tr
                        key={row.id}
                        className="payer-table"
                    >
                        {row.getVisibleCells().map((cell, cellIndex) => (
                            <td
                                key={cell.id}
                                style={{
                                    width: useColWidths && colWidths[cellIndex],
                                }}
                            >{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>))}
                    </tr>)))}
                </tbody>
            </table>


            {/* Pagination Controls */}
            <div className="d-flex justify-content-between mb-2 mt-2">
                <button className="sos-custom-btn sos-custom-btn-css sos-secondary-btn-padding"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}>
                    Previous
                </button>
                <span>
          Page {pagination.pageIndex + 1} of {table.getPageCount() || 1}
        </span>
                <button
                    className="sos-custom-btn sos-custom-btn-css sos-secondary-btn-padding"
                    onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>

                    Next
                </button>
            </div>
        </div>);
};

export default ReactTableServerSide;
